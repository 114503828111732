import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const vaPrimaryPalette = {
  50: '#e6e1ea',
  100: '#c0b4ca',
  200: '#9682a7',
  300: '#6c5083',
  400: '#4d2b69',
  500: '#2d054e',
  600: '#280447',
  700: '#22043d',
  800: '#1c0335',
  900: '#110125',
  A100: '#9060ff',
  A200: '#6d2dff',
  A400: '#4c00f9',
  A700: '#4400e0',
};

const vaAccentPalette = {
  50: '#fbe2e2',
  100: '#f6b6b6',
  200: '#f08585',
  300: '#ea5454',
  400: '#e62f2f',
  500: '#e10a0a',
  600: '#dd0909',
  700: '#d90707',
  800: '#d50505',
  900: '#cd0303',
  A100: '#fff5f5',
  A200: '#ffc2c2',
  A400: '#ff8f8f',
  A700: '#ff7575',
};

export const vaTheme = responsiveFontSizes(
  createTheme({
    cssVariables: { cssVarPrefix: '' },

    typography: {
      ...typographyDefaults,
      fontFamily: 'GT-America',
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: vaPrimaryPalette[500],
            light: vaPrimaryPalette[300],
            dark: vaPrimaryPalette[700],
          },
          secondary: {
            main: vaAccentPalette[500],
            light: vaAccentPalette[300],
            dark: vaAccentPalette[700],
          },
          error: {
            main: '#f23221',
            light: '#e66b67',
            dark: '#dd2825',
          },
        },
      },
    },
    components: componentDefaults,
  })
);
