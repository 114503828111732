import * as v from 'valibot';
import {
  setPartnerCreatedExerciseFlowParams,
  setHTSCreatedExerciseFlowParams,
} from '../../products/cfar/store';
import { Partner } from '../../common/constants/partner';

const paramSchema = v.object({
  hSessionId: v.pipe(v.string(), v.nonEmpty()),
  exerciseId: v.pipe(v.string(), v.nonEmpty()),
  callbackUrl: v.optional(v.string()),
  redirectbackUrl: v.optional(v.string()),
});

// Param schema for flow where exercise is created on landing
const htsCreatedExerciseFlowParamSchema = v.object({
  contractId: v.pipe(v.string(), v.nonEmpty()),
  partnerId: v.pipe(v.string(), v.nonEmpty()),
});

export async function sanitizeUrl() {
  const url = new URL(window.location.href.split('#/').join(''));
  const paramsObject = Object.fromEntries(url.searchParams);

  // Try partner created exercise flow first
  const result = v.safeParse(paramSchema, paramsObject);
  if (result.success) {
    const {
      hSessionId: sessionId,
      exerciseId,
      callbackUrl,
      redirectbackUrl: redirectBackUrl,
    } = result.output;
    setPartnerCreatedExerciseFlowParams({
      sessionId,
      redirectBackUrl,
      callbackUrl,
      exerciseId,
    });
  } else {
    // Try HTS created exercise flow
    const contractResult = v.safeParse(
      htsCreatedExerciseFlowParamSchema,
      paramsObject
    );
    if (contractResult.success) {
      const { contractId, partnerId } = contractResult.output;
      setHTSCreatedExerciseFlowParams({
        contractId,
        partnerId: partnerId as Partner,
      });
    }
  }

  // Always clean the URL
  url.hash = '';
  url.search = '';
  window.history.replaceState({}, '', url.toString());
}
