export const CURRENT_ENVIRONMENT = import.meta.env.MODE as
  | 'development'
  | 'staging'
  | 'production';

export const VERSION = import.meta.env.VITE_VERSION || 'local';

export const API_URL = '/api/v1.1';

export const MOCK_ENABLED =
  CURRENT_ENVIRONMENT !== 'production' &&
  import.meta.env.VITE_USE_MOCK === 'true';

export const DD_SERVICE = 'hts-air-cfar-exercise-app';
