import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const defaultPrimaryPalette = {
  50: '#e9eaeb',
  100: '#c9cacc',
  200: '#a5a7aa',
  300: '#808488',
  400: '#65696f',
  500: '#4a4f55',
  600: '#43484e',
  700: '#3a3f44',
  800: '#32363b',
  900: '#22262a',
  A100: '#7ab1fb',
  A200: '#4894fa',
  A400: '#1076ff',
  A700: '#0069f6',
};

const defaultAccentPalette = {
  50: '#e0eaef',
  100: '#b3cbd7',
  200: '#80a8bc',
  300: '#4d85a1',
  400: '#266a8c',
  500: '#005078',
  600: '#004970',
  700: '#004065',
  800: '#00375b',
  900: '#002748',
  A100: '#7cb7ff',
  A200: '#499aff',
  A400: '#167eff',
  A700: '#0070fc',
};

export const defaultTheme = responsiveFontSizes(
  createTheme({
    cssVariables: { cssVarPrefix: '' },
    typography: {
      ...typographyDefaults,
      fontFamily: 'Roboto',
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: defaultPrimaryPalette[500],
            light: defaultPrimaryPalette[300],
            dark: defaultPrimaryPalette[700],
          },
          secondary: {
            main: defaultAccentPalette[500],
            light: defaultAccentPalette[300],
            dark: defaultAccentPalette[700],
          },
          error: {
            main: '#f23221',
            light: '#e66b67',
            dark: '#dd2825',
          },
        },
      },
    },
    components: componentDefaults,
  })
);
