import { Logger } from '../products/cfar/utilities/logger';
import { DEFAULT_LNG, DEFAULT_NAMESPACE } from './constants';
import i18next, { InitOptions } from 'i18next';

function getResourceURL(lng: string, namespace: string) {
  return new URL(`./locales/${lng}/${namespace}.json`, import.meta.url).href;
}

export const config: InitOptions = {
  lng: DEFAULT_LNG,
  ns: [DEFAULT_NAMESPACE],
  defaultNS: DEFAULT_NAMESPACE,
  fallbackNS: DEFAULT_NAMESPACE,
  fallbackLng: {
    'zh-CN': ['zh-Hans', DEFAULT_LNG],
    'zh-HK': ['zh-Hant', DEFAULT_LNG],
    'zh-TW': ['zh-Hant', DEFAULT_LNG],
    default: [DEFAULT_LNG],
  },
  backend: {
    loadPath: (lng: string, namespace: string) => {
      return getResourceURL(lng, namespace);
    },
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  lowerCaseLng: true,
  saveMissing: true,
  missingKeyHandler: (
    _lngs: readonly string[],
    namespace: string,
    key: string
  ) => {
    Logger.warn(`i18next::missingKey ${i18next.language} ${namespace} ${key}`);
  },
  react: {
    // https://react.i18next.com/latest/trans-component#usage-with-simple-html-elements-like-less-than-br-greater-than-and-others-v10.4.0
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b'],
  },
};
