import { getClient } from './getClient';
import { HEADER_HTS_SESSION } from '../../../common/constants/http';

interface CreateExerciseParams {
  contract_id: string;
}

export async function createExercise({ contract_id }: CreateExerciseParams) {
  const client = getClient(); // No sessionId needed for creation
  const apiResponse = await client.postCustomerCfarExercisesRaw({
    createCfarExerciseRequest: {
      contractId: contract_id,
    },
  });

  // Extract session ID from raw response headers
  const sessionId = apiResponse.raw.headers.get(HEADER_HTS_SESSION);
  const response = await apiResponse.value();

  if (!sessionId) {
    throw new Error('Failed to create exercise: No session ID in response');
  }

  return {
    exerciseId: response.id,
    sessionId: sessionId,
  };
}
